import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { fetchRapports, rapportsSelector } from './rapportsSlice';
import { Rapport } from '../rapport/Rapport';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import styles from './Rapports.module.css';
import format from "date-fns/format";
import fr from 'date-fns/locale/fr';

export const Rapports = () => {

  const dispatch = useDispatch()
  const service = useSelector(state => state.service.service)
  const cumul = useSelector(state => state.cumul.cumul)
  const cumul_bool = useSelector(state => state.rapports.cumul)
  const {rapports, loading, hasErrors} = useSelector(rapportsSelector)

  useEffect(() => {
    dispatch(fetchRapports())
  }, [dispatch])

  const renderRapports = () => {
    let side = false
    return rapports.map((rapport) => <Rapport key={rapport.rapport_id} rapport={rapport} side={side} /> )
  }

  const renderTitre = () => {
    
    if(cumul_bool == true) {
      return <h3>Les rapports du {cumul.date_min_affichage} au {cumul.date_max_affichage}</h3>
    } else {

      const date = format(Date.parse(service[0].date_service), 'EEEE dd MMMM yyyy', {locale: fr})

      return <h3>Les rapports pour le Service n°{service[0].num_tab_res_affichage} du {date}</h3>
    }
    
  }

  if (loading) return <Alert variant='secondary'> Chargement de la liste </Alert>
  if (hasErrors) return <Alert variant='danger'> Impossible de charger la liste </Alert>

  return (
    <div>
      <div className={styles.titre}>{renderTitre()}</div>
      <div className={styles.grille}>
        {renderRapports()}
      </div>
    </div>
  )

}
