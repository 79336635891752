import React from 'react';
import ReactDOM from 'react-dom';
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux';
import { CustomProvider } from 'rsuite';
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl'
import fr from 'date-fns/locale/fr';
import './index.css';
import { App } from './App';
import store from './app/store';
import * as serviceWorker from './serviceWorker';
import 'chart.js';
import 'chartjs-plugin-datalabels';


let persistor = persistStore(store)

const Calendar = {

  monday: 'lu',
  tuesday: 'ma',
  wednesday: 'me',
  thursday: 'je',
  friday: 've',
  saturday: 'sa',
  sunday: 'di',
  ok: 'Valider',
  today: 'Aujourd\'hui',
  yesterday: 'Hier',
  hours: 'heures',
  minutes: 'minutes',
  seconds: 'secondes',
  /**
   * Format of the string is based on Unicode Technical Standard #35:
   * https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
   **/
  formattedMonthPattern: 'MMM yyyy',
  formattedDayPattern: 'dd MMM yyyy',
  dateLocale: fr
};

const locale = {
  common: {
    loading: 'Chargement...',
    emptyMessage: 'Aucune donnée'
  },
  Plaintext: {
    unfilled: 'Unfilled',
    notSelected: 'Pas choisi',
    notUploaded: 'Pas Chargé'
  },
  Pagination: {
    more: 'Plus',
    prev: 'Précédent',
    next: 'Suivnat',
    first: 'Premier',
    last: 'Dernier',
    limit: '{0} / page',
    total: 'Total lignes: {0}',
    skip: 'Go to{0}'
  },
  Calendar,
  DatePicker: {
    ...Calendar
  },
  DateRangePicker: {
    ...Calendar,
    last7Days: '7 dernier jours'
  },
  Picker: {
    noResultsText: 'No results found',
    placeholder: 'Select',
    searchPlaceholder: 'Search',
    checkAll: 'All'
  },
  InputPicker: {
    newItem: 'New item',
    createOption: 'Create option "{0}"'
  },
  Uploader: {
    inited: 'Initial',
    progress: 'Uploading',
    error: 'Error',
    complete: 'Finished',
    emptyFile: 'Empty',
    upload: 'Upload'
  },
  CloseButton: {
    closeLabel: 'Close'
  },
  Breadcrumb: {
    expandText: 'Show path'
  },
  Toggle: {
    on: 'Open',
    off: 'Close'
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CustomProvider theme="light" locale={locale}>
          <App />
        </CustomProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
