import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Services } from "../services/Services";
import Navbar from "react-bootstrap/Navbar";
import {
  fetchDeconnexion,
  setRestaurant,
  changementRestaurant,
} from "../connexion/connexionSlice";
import history from "../../history";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { NavLink, useLocation } from "react-router-dom";
import { ArrowRepeat } from "react-bootstrap-icons";
import styles from "./Menu.module.css";
import { format } from "date-fns";
import "bootstrap/dist/css/bootstrap.min.css";

export const Menu = () => {
  const lastUpdate = useSelector((state) => state.admin.lastUpdated);
  const utilisateur = useSelector((state) => state.connexion.utilisateur);
  const restaurant_choisi = useSelector(
    (state) => state.connexion.restaurant_choisi
  );
  const cumul_state = useSelector((state) => state.rapports.cumul);
  const dispatch = useDispatch();
  const dashboard = useRef();
  const rapport = useRef();
  const cumul = useRef();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname == "/service/rapports") rapport.current.focus();

    if (location.pathname == "/rapport/financier") rapport.current.focus();

    if (location.pathname == "/service/rapports" && cumul_state == true)
      cumul.current.focus();

    if (location.pathname == "/rapport/financier" && cumul_state == true)
      cumul.current.focus();
  }, []);

  const handleChange = (e) => {
    dispatch(changementRestaurant(e.target.value));
  };

  const liste_restaurant = () => {
    if (utilisateur.multi_resto == true) {
      return (
        <select type="select" onChange={(e) => handleChange(e)}>
          {utilisateur.liste_multi_resto.map((resto, index) =>
            restaurant_choisi == index ? (
              <option value={index} selected>
                {resto.rest_nom}
              </option>
            ) : (
              <option value={index}>{resto.rest_nom}</option>
            )
          )}
        </select>
      );
    } else {
      return <h5 className={styles.nom_resto}>{utilisateur.rest_nom}</h5>;
    }
  };

  if (utilisateur.type == "superadmin") {
    const lastUpdateDisplay = lastUpdate
      ? format(new Date(lastUpdate), "dd/MM/yyyy HH:mm:ss")
      : format(new Date(), "dd/MM/yyyy HH:mm:ss");
    return (
      <Navbar
        as="div"
        className={styles.menu}
        bg="dark"
        sticky="top"
        expand="lg"
        variant="light"
      >
        <Navbar.Brand as="div" href="/dashboard" className={styles.font}>
          <img
            className={styles.logo_connexion}
            src={process.env.PUBLIC_URL + "/POKKYWEB_noir_L.png"}
          />
        </Navbar.Brand>
        <Navbar.Toggle
          as="div"
          className={styles.bouton_mobile}
          aria-controls="basic-navbar-nav"
        />
        <Navbar.Collapse as="div" id="basic-navbar-nav">
          <Nav className="mr-auto" as="div">
            <Nav.Link
              as="div"
              onClick={() => dispatch(fetchDeconnexion())}
              className={styles.font}
            >
              Deconnexion
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <span style={{ paddingRight: "40px" }}>
          <strong>Dernière mise à jour des données : </strong>
          {lastUpdateDisplay}
        </span>
      </Navbar>
    );
  } else {
    return (
      <Navbar
        as="div"
        className={styles.menu}
        bg="dark"
        sticky="top"
        expand="lg"
        variant="light"
      >
        <Navbar.Brand as="div" href="/dashboard" className={styles.font}>
          <img
            className={styles.logo_connexion}
            src={process.env.PUBLIC_URL + "/POKKYWEB_noir_L.png"}
          />
        </Navbar.Brand>
        <Navbar.Toggle
          as="div"
          className={styles.bouton_mobile}
          aria-controls="basic-navbar-nav"
        />
        <Navbar.Collapse as="div" id="basic-navbar-nav">
          <Nav as="div" className="mr-auto">
            {liste_restaurant()}
            <button
              className={styles.navigation}
              onClick={() => history.go(0)}
              title="Rafraichir la page"
            >
              <ArrowRepeat color="white" size={30} />
            </button>
            <Nav.Link as="div">
              <NavLink
                to="/dashboard"
                ref={dashboard}
                activeClassName={styles.active}
                className={styles.font}
              >
                Tableau de bord
              </NavLink>
            </Nav.Link>
            <Nav.Link as="div">
              <NavLink
                to="/services"
                ref={rapport}
                activeClassName={styles.active}
                className={styles.font}
              >
                Rapport
              </NavLink>
            </Nav.Link>
            <Nav.Link as="div">
              <NavLink
                to="/cumul"
                ref={cumul}
                activeClassName={styles.active}
                className={styles.font}
              >
                Cumul
              </NavLink>
            </Nav.Link>
          </Nav>
          <Nav className="mr-auto" as="div">
            <Nav.Link
              as="div"
              onClick={() => dispatch(fetchDeconnexion())}
              className={styles.font}
            >
              Deconnexion
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
};
