import React, { useState } from 'react';
import { fetchRapport } from './rapportSlice';
import Card from 'react-bootstrap/Card';
import styles from './Rapport.module.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import history from '../../history';
import { useSelector, useDispatch } from 'react-redux';
import {
  Redirect,
  useHistory,
  useParams,
  withRouter
} from "react-router-dom";

export const Rapport = ({rapport, side}) => {
  const dispatch = useDispatch()

  var OuvrirRapport = (e) => {
    dispatch(fetchRapport(rapport))
  }

  if (side == false) {
    return (
      <>
        <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip>
            Cliquer pour ouvrir le rapport.
          </Tooltip>
        } >
          <Card
          bg="dark"
          text= 'white'
          className={styles.service}
          onClick={OuvrirRapport}
          >
            <Card.Header className={styles.longueur}>Rapport n°{rapport.rapport_id}</Card.Header>
            <Card.Body>
              <Card.Text>
                {rapport.rapport_nom}
              </Card.Text>
            </Card.Body>
          </Card>
        </OverlayTrigger>
      </>
    )
  } else {
    return (
      <>
        <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip>
            Cliquer pour ouvrir le rapport.
          </Tooltip>
        } >
          <Card
          bg="dark"
          text= 'white'
          className={styles.service}
          onClick={OuvrirRapport}
          >
            <Card.Header>{rapport.rapport_nom}</Card.Header>
          </Card>
        </OverlayTrigger>
      </>
    )
  }

}
