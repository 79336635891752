import React, { useEffect } from 'react';
import history from '../../history';
import { useSelector, useDispatch } from 'react-redux';
import { fetchServices, servicesSelector } from './servicesSlice';
import { getSelectedService } from '../service/serviceSlice'
import { unsetRapportCumul } from '../rapports/rapportsSlice';
import { Service } from '../service/Service';
import Button from 'react-bootstrap/Button';
import styles from './Services.module.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import 'moment/locale/fr';
import 'bootstrap/dist/css/bootstrap.min.css';

const localizer = momentLocalizer(moment)
const messages = {
  allDay: 'Journée',
  previous: 'Précédent',
  next: 'Suivant',
  today: 'Aujourd\'hui',
  month: 'mois',
  week: 'semaine',
  day: 'jour',
  agenda: 'Agenda',
  date: 'date',
  time: 'heure',
  event: 'événement', 
  showMore: total => `+ ${total} événement(s) supplémentaire(s)`
}
export const Services = () => {
  
  const dispatch = useDispatch()
  const services = useSelector(state => state.services) 
  const restaurant_choisi  = useSelector((state) => state.connexion.restaurant_choisi)

  const changementdepage = () => {

    history.push("/service/rapports")

  }

  const OuvrirRapport = (event) => {

    dispatch(getSelectedService(services.services.filter(service => service.num_tab_res === event.id)));
    dispatch(unsetRapportCumul())
    .then(changementdepage());
    
  }

  useEffect(() => {
    dispatch(fetchServices())
  }, [restaurant_choisi]);

  const renderServices = () => {

    if(services.loaded == true) {
      var myEventsList = []
  
      services.services.map((service) => myEventsList.push({ id: service.num_tab_res, title: "Service n°"+service.num_tab_res_pokky, start: service.date_service, end: service.date_service, service:service}))
  
      return (
        <div className={styles.div_calendrier}>
          <div>
            <Calendar
            messages={messages}
            views={["month"]}
            localizer={localizer}
            popup={"true"}
            events={myEventsList}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 600 }}
            onSelectEvent={event => OuvrirRapport(event)}
            />
          </div>
        </div>
      )
    } else {
      return (
        <Spinner className={styles.spinner} variant="danger" animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      )
    }
    
  }

  return (
    <div>
      {renderServices()}
    </div>
  )

}
