import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../Constants";
import axios from "axios";
import history from "../../history";
import { fetchDashboard } from "../dashboard/dashboardSlice";
import { fetchServices } from "../services/servicesSlice";

export const initialState = {
  utilisateur: { id: "", nom: "", connecte: false },
  restaurant_choisi: 0,
};

export const changementRestaurant = createAsyncThunk(
  "connexion/changementRestaurant",
  async (restaurant_choisi, thunkAPI) => {
    thunkAPI.dispatch(setRestaurant(restaurant_choisi));
    /* .then(thunkAPI.dispatch(fetchDashboard('hebdomadaire'))) */
  }
);

const connexionSlice = createSlice({
  name: "connexion",
  initialState,
  reducers: {
    getConnexionSuccess: (state, { payload }) => {
      state.utilisateur = payload;
      state.loading = false;
      state.hasErrors = false;
      // state.restaurant_choisi = payload.rest_id;
      state.restaurant_choisi = initialState.restaurant_choisi;
      //console.log("connexionSlice.js - getConnexionSuccess - Utilisateur:", state.utilisateur);

    },
    getConnexionFailure: (state, { payload }) => {
      state.utilisateur = initialState.utilisateur;
      state.errorMessage = payload;
      state.loading = false;
      state.hasErrors = true;
      state.restaurant_choisi = initialState.restaurant_choisi;
    },
    getDeconnexionSuccess: (state) => {
      state.utilisateur = initialState.utilisateur;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = initialState.errorMessage;
      state.restaurant_choisi = initialState.restaurant_choisi;
    },
    getDeconnexionFailure: (state, { payload }) => {
      state.utilisateur = initialState.utilisateur;
      state.loading = false;
      state.hasErrors = true;
      state.restaurant_choisi = initialState.restaurant_choisi;
    },
    setRestaurant: (state, { payload }) => {
      state.restaurant_choisi = payload;
    },
  },
  extraReducers: {
    [changementRestaurant.fulfilled]: (state, action) => {
      console.log("test12345678910");
    },
  },
});

export const {
  getConnexionSuccess,
  getConnexionFailure,
  getDeconnexionSuccess,
  getDeconnexionFailure,
  setRestaurant,
} = connexionSlice.actions;

export const connexionSelector = (state) => state.connexion;
export default connexionSlice.reducer;

export function fetchConnexion(rest, id, mdp) {
  return async (dispatch) => {
    const request_data = {
      rest_id: rest,
      id: id,
      mdp: mdp,
    };

    const config = {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    };

    try {
      const response = await axios.post(API_URL + "/compte/connexion/utilisateur", request_data, config);
      //console.log("fetchConnexion - Réponse API:", response.data);  // Ajoutez ceci pour voir la réponse API
      dispatch(getConnexionSuccess(response.data));
      if (response.data.type !== "superadmin") {
        history.replace("/dashboard");
      } else {
        history.replace("/admin");
      }
    } catch (error) {
      //console.log("fetchConnexion - Erreur:", error.response); // Pour voir l'erreur
      dispatch(getConnexionFailure({
        status: error.response.status,
        message: error.response.data,
      }));
    }
  };
}

export function fetchDeconnexion() {
  return async (dispatch, getState) => {
    const utilisateur = getState().connexion.utilisateur;

    const config = {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    };

    const response = await axios
      .patch(API_URL + "/compte/deconnexion/utilisateur", utilisateur, config)

      .then((res) => {
        dispatch(getDeconnexionSuccess());
        history.push("/connexion/" + utilisateur.rest_id_affichage);
      })
      .catch((error) => {
        dispatch(getDeconnexionFailure());
      });
  };
}
