import { createSlice } from "@reduxjs/toolkit";
import { fetchDeconnexion } from "../connexion/connexionSlice";
import { API_URL } from "../../Constants";
import { Message, toaster } from "rsuite";
import axios from "axios";

export const initialState = {
  loading: false,
  hasErrors: false,
  loaded: false,
  loaded_groupe: false,
  loaded_restaurant: false,
  loaded_utilisateur: false,
  loaded_affectation: false,
  admin: [],
  groupe: [],
  utilisateur: [],
  restaurant: [],
  ajout: false,
  lastUpdated: null,
  errorMessage: "",
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    getAdmin: (state) => {
      state.loading = true;
      state.loaded = false;
      state.loaded_groupe = false;
      state.loaded_restaurant = false;
      state.loaded_utilisateur = false;
      state.loaded_affectation = false;
    },
    getGroupeSuccess: (state, { payload }) => {
      state.groupe = payload;
      state.loading = false;
      state.loaded = true;
      state.hasErrors = false;
      state.loaded_groupe = true;
    },
    getRestaurantSuccess: (state, { payload }) => {
      state.restaurant = payload;
      state.loading = false;
      state.loaded = true;
      state.hasErrors = false;
      state.loaded_restaurant = true;
    },
    getUtilisateurSuccess: (state, { payload }) => {
      state.utilisateur = payload;
      state.loading = false;
      state.loaded = true;
      state.hasErrors = false;
      state.loaded_utilisateur = true;
    },
    getAffectationSuccess: (state, { payload }) => {
      state.affectation = payload;
      state.loading = false;
      state.loaded = true;
      state.hasErrors = false;
      state.loaded_affectation = true;
    },
    setAdminFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
      state.loaded = false;
      state.loaded_groupe = false;
      state.loaded_restaurant = false;
      state.loaded_utilisateur = false;
      state.loaded_affectation = false;
    },
    setAdminAjout: (state) => {
      state.ajout = true;
    },
    setGroupeTest: (state) => {
      state.loaded_groupe = false;
    },
    setAdminAjoutGroupe: (state) => {
      state.ajout = true;
      state.loaded_groupe = true;
    },
    setAdminAjoutRestaurant: (state) => {
      state.ajout = true;
      state.loaded_restaurant = true;
    },
    setAdminAjoutUtilisateur: (state) => {
      state.ajout = true;
      state.loaded_utilisateur = true;
    },
    setAdminAjoutAffectation: (state) => {
      state.ajout = true;
      state.loaded_affectation = true;
    },
    setAdminModification: (state) => {
      state.modification = true;
    },
    setAdminSuppression: (state) => {
      state.suppression = true;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    resetErrorMessage: (state) => {
      state.errorMessage = "";
    },
    updateLastUpdated: (state, action) => {
      state.lastUpdated = action.payload;
    },
  },
});

export const {
  getAdmin,
  getGroupeSuccess,
  setAdminFailure,
  getRestaurantSuccess,
  getUtilisateurSuccess,
  getAffectationSuccess,
  setAdminAjout,
  setAdminModification,
  setAdminSuppression,
  setAdminAjoutGroupe,
  setAdminAjoutUtilisateur,
  setAdminAjoutRestaurant,
  setAdminAjoutAffectation,
  setGroupeTest,
  setErrorMessage,
  resetErrorMessage,
  updateLastUpdated,
} = adminSlice.actions;

export const adminSelector = (state) => state.admin;

export default adminSlice.reducer;

export function fetchGroupe() {
  return async (dispatch, getState) => {
    dispatch(getAdmin());

    const config = {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    };

    const response = axios
      .get(API_URL + "/compte/admin/groupe", config)
      .then((res) => {
        dispatch(getGroupeSuccess(res.data));
      })
      .catch((error) => {
        if (error.response.status == 401) {
          dispatch(fetchDeconnexion());
        }

        dispatch(setAdminFailure());
      });
  };
}

export function patchGroupe(data) {
  return async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getState().connexion.utilisateur.csrf_token,
        },
        withCredentials: true,
      };

      const response = await axios
        .patch(API_URL + "/compte/admin/groupe", data, config)
        .then((res) => {
          dispatch(getGroupeSuccess(res.data));
          toaster.push(
            <Message showIcon type="success">
              groupe modifié avec succès.
            </Message>,
            { placement: "topEnd" }
          );
        });
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(fetchDeconnexion());
        } else if (
          error.response.status === 409 ||
          error.response.status === 400
        ) {
          // Gérer ici les erreurs d'unicité ou d'autres erreurs de requête
          dispatch(
            setErrorMessage(
              error.response.data.error || "Une erreur s'est produite"
            )
          );
        } else {
          // Gérer les autres types d'erreurs
          dispatch(setErrorMessage("Une erreur s'est produite"));
        }
      } else {
        // Erreur réseau ou autre
        dispatch(setErrorMessage("Erreur de réseau ou autre erreur"));
      }
    }
  };
}

export function postGroupe(data) {
  return async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getState().connexion.utilisateur.csrf_token,
        },
        withCredentials: true,
      };

      const response = await axios.post(
        API_URL + "/compte/admin/groupe",
        data,
        config
      );
      if (response.status === 200) {
        dispatch(setAdminAjoutGroupe());
        dispatch(fetchGroupe());
        // Afficher le message de succès
        toaster.push(
          <Message showIcon type="success">
            Groupe ajouté avec succès.
          </Message>,
          { placement: "topEnd" }
        );
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(fetchDeconnexion());
        } else if (
          error.response.status === 409 ||
          error.response.status === 400
        ) {
          // Gérer ici les erreurs d'unicité ou d'autres erreurs de requête
          dispatch(
            setErrorMessage(
              error.response.data.error || "Une erreur s'est produite"
            )
          );
        } else {
          // Gérer les autres types d'erreurs
          dispatch(setErrorMessage("Une erreur s'est produite"));
        }
      } else {
        // Erreur réseau ou autre
        dispatch(setErrorMessage("Erreur de réseau ou autre erreur"));
      }
    }
  };
}

export function deleteGroupe(data) {
  return async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getState().connexion.utilisateur.csrf_token,
        },
        withCredentials: true,
      };

      const response = await axios
        .delete(API_URL + "/compte/admin/groupe/" + data, config)
        .then((res) => {
          if (res.status === 200) {
            dispatch(fetchGroupe());
            toaster.push(
              <Message showIcon type="success">
                Groupe supprimé avec succès.
              </Message>,
              { placement: "topEnd" }
            );
          }
        });
    } catch (error) {
      console.error("Parsing error:", error);

      if (error["response"] !== undefined) {
        if (error.response.status == 401) {
          dispatch(fetchDeconnexion());
        }
      }
    }
  };
}

export function fetchRestaurant() {
  return async (dispatch, getState) => {
    dispatch(getAdmin());

    const config = {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    };

    const response = axios
      .get(API_URL + "/compte/admin/restaurant", config)
      .then((res) => {
        dispatch(getRestaurantSuccess(res.data));
      })
      .catch((error) => {
        if (error.response.status == 401) {
          dispatch(fetchDeconnexion());
        }

        dispatch(setAdminFailure());
      });
  };
}

export function patchRestaurant(data) {
  return async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getState().connexion.utilisateur.csrf_token,
        },
        withCredentials: true,
      };

      const response = await axios
        .patch(API_URL + "/compte/admin/restaurant", data, config)
        .then((res) => {
          dispatch(getRestaurantSuccess(res.data));
          toaster.push(
            <Message showIcon type="success">
              Restaurant modifié avec succès.
            </Message>,
            { placement: "topEnd" }
          );
        });
    } catch (error) {
      if (error["response"] !== undefined) {
        if (error.response.status == 401) {
          dispatch(fetchDeconnexion());
        }
      }
    }
  };
}

export function postRestaurant(data) {
  return async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getState().connexion.utilisateur.csrf_token,
        },
        withCredentials: true,
      };

      // Premier appel pour créer le restaurant
      const response = await axios.post(
        API_URL + "/compte/ajout/rest",
        data,
        config
      );

      // Après la création réussie
      dispatch(fetchRestaurant());
      toaster.push(
        <Message showIcon type="success">
          Restaurant et son entrée historique ajoutés avec succès.
        </Message>,
        { placement: "topEnd" }
      );
    } catch (error) {
      // Gérer les erreurs ici
      if (error.response.status === 401) {
        dispatch(fetchDeconnexion());
      }
    }
  };
}

export function deleteRestaurant(data) {
  return async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getState().connexion.utilisateur.csrf_token,
        },
        withCredentials: true,
      };

      // Premier appel pour supprimer le restaurant
      const responseRestaurant = await axios.delete(
        API_URL + "/compte/supp/rest/" + data,
        config
      );
      dispatch(fetchRestaurant());
      toaster.push(
        <Message showIcon type="success">
          Restaurant et historique associé supprimés avec succès.
        </Message>,
        { placement: "topEnd" }
      );
    } catch (error) {
      console.error("Erreur lors de la suppression:", error);

      if (error.response && error.response.status === 401) {
        dispatch(fetchDeconnexion());
      }
    }
  };
}

export function fetchUtilisateur() {
  return async (dispatch, getState) => {
    dispatch(getAdmin());

    const config = {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    };

    const response = axios
      .get(API_URL + "/compte/admin/utilisateur", config)
      .then((res) => {
        dispatch(getUtilisateurSuccess(res.data));
      })
      .catch((error) => {
        if (error.response.status == 401) {
          dispatch(fetchDeconnexion());
        }

        dispatch(setAdminFailure());
      });
  };
}

export function postUtilisateur(data) {
  return async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getState().connexion.utilisateur.csrf_token,
        },
        withCredentials: true,
      };

      const response = await axios
        .post(API_URL + "/compte/admin/utilisateur", data, config)
        .then((res) => {
          if (res.status == 200) {
            dispatch(setAdminAjoutUtilisateur());
            dispatch(fetchUtilisateur());
            toaster.push(
              <Message showIcon type="success">
                Utilisateur créer avec succès.
              </Message>,
              { placement: "topEnd" }
            );
          }
        });
    } catch (error) {
      if (error["response"] !== undefined) {
        if (error.response.status == 401) {
          dispatch(fetchDeconnexion());
        }
      }
    }
  };
}

export function patchUtilisateur(data) {
  return async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getState().connexion.utilisateur.csrf_token,
        },
        withCredentials: true,
      };

      const response = await axios
        .patch(API_URL + "/compte/admin/utilisateur", data, config)
        .then((res) => {
          dispatch(setAdminAjout());
          toaster.push(
            <Message showIcon type="success">
              Utilisateur modifié avec succès.
            </Message>,
            { placement: "topEnd" }
          );
        });
    } catch (error) {
      if (error["response"] !== undefined) {
        if (error.response.status == 401) {
          dispatch(fetchDeconnexion());
        }
      }
    }
  };
}

export function deleteUtilisateur(data) {
  return async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getState().connexion.utilisateur.csrf_token,
        },
        withCredentials: true,
      };

      const response = await axios
        .delete(API_URL + "/compte/admin/utilisateur/" + data, config)
        .then((res) => {
          if (res.status == 200) {
            dispatch(fetchUtilisateur());
            toaster.push(
              <Message showIcon type="success">
                Utilisateur supprimé avec succès.
              </Message>,
              { placement: "topEnd" }
            );
          }
        });
    } catch (error) {
      console.error("Parsing error:", error);

      if (error["response"] !== undefined) {
        if (error.response.status == 401) {
          dispatch(fetchDeconnexion());
        }
      }
    }
  };
}

export function fetchAffectation() {
  return async (dispatch, getState) => {
    dispatch(getAdmin());

    const config = {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    };

    const response = axios
      .get(API_URL + "/compte/admin/affectation", config)
      .then((res) => {
        dispatch(getAffectationSuccess(res.data));
      })
      .catch((error) => {
        if (error.response.status == 401) {
          dispatch(fetchDeconnexion());
        }

        dispatch(setAdminFailure());
      });
  };
}

export function patchAffectation(data) {
  return async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getState().connexion.utilisateur.csrf_token,
        },
        withCredentials: true,
      };

      const response = await axios
        .patch(API_URL + "/compte/admin/affectation", data, config)
        .then((res) => {
          dispatch(getAffectationSuccess(res.data));
          toaster.push(
            <Message showIcon type="success">
              Affectation modifié avec succès.
            </Message>,
            { placement: "topEnd" }
          );
        });
    } catch (error) {
      if (error["response"] !== undefined) {
        if (error.response.status == 401) {
          dispatch(fetchDeconnexion());
        }
      }
    }
  };
}

export function deleteAffectation(data) {
  return async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getState().connexion.utilisateur.csrf_token,
        },
        withCredentials: true,
      };

      const response = await axios
        .delete(API_URL + "/compte/admin/affectation/" + data, config)
        .then((res) => {
          if (res.status == 200) {
            dispatch(fetchAffectation());
            toaster.push(
              <Message showIcon type="success">
                Affectation supprimé avec succès.
              </Message>,
              { placement: "topEnd" }
            );
          }
        });
    } catch (error) {
      console.error("Parsing error:", error);

      if (error["response"] !== undefined) {
        if (error.response.status == 401) {
          dispatch(fetchDeconnexion());
        }
      }
    }
  };
}

export function postAffectation(data) {
  return async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getState().connexion.utilisateur.csrf_token,
        },
        withCredentials: true,
      };

      const response = await axios
        .post(API_URL + "/compte/admin/affectation", data, config)
        .then((res) => {
          if (res.status == 200) {
            dispatch(setAdminAjoutAffectation());
            dispatch(fetchAffectation());
            toaster.push(
              <Message showIcon type="success">
                Affectation créer avec succès.
              </Message>,
              { placement: "topEnd" }
            );
          }
        });
    } catch (error) {
      if (error["response"] !== undefined) {
        if (error.response.status == 401) {
          dispatch(fetchDeconnexion());
        }
      }
    }
  };
}
