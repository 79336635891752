import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchDeconnexion } from "../connexion/connexionSlice";
import { API_URL } from "../../Constants";
import Cookies from "js-cookie";
import axios from "axios";

export const initialState = {
  loading: false,
  hasErrors: false,
  loaded: false,
  type: "",
  dashboard: [
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
  ],
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    getDashboard: (state) => {
      state.loading = true;
      state.loaded = false;
    },
    getDashboardSuccess: (state, { payload }) => {
      state.dashboard = payload;
      state.loading = false;
      state.hasErrors = false;
      state.loaded = true;
    },
    getDashboardFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
      state.loaded = false;
    },
    setType: (state, { payload }) => {
      state.type = payload;
    },
  },
});

export const {
  getDashboard,
  getDashboardSuccess,
  getDashboardFailure,
  setType,
} = dashboardSlice.actions;

export const dashboardSelector = (state) => state.dashboard;

export default dashboardSlice.reducer;

export function fetchDashboard(type, date_min = 0, date_max = 0) {
  return async (dispatch, getState) => {
    const restaurant_choisi = getState().connexion.restaurant_choisi;

    dispatch(getDashboard());

    dispatch(setType(type));

    try {
      const config = {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      };

      const response = await axios.get(
        API_URL +
          "/web/dashboard/" +
          type +
          "/" +
          date_min +
          "/" +
          date_max +
          "/" +
          restaurant_choisi,
        config
      );

      const data = await response.data;

      dispatch(getDashboardSuccess(data, type));
    } catch (error) {
      if (error["response"] !== undefined) {
        if (error.response.status == 401) {
          dispatch(fetchDeconnexion());
        }
      }

      dispatch(getDashboardFailure());
    }
  };
}
