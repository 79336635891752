import {createSlice} from '@reduxjs/toolkit'
import { fetchDeconnexion } from '../connexion/connexionSlice'
import {API_URL} from '../../Constants'
import axios from 'axios'

export const initialState = {
  loading: false,
  hasErrors: false,
  services: [],
  loaded: false
}

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    getServices: (state) => {
      state.loading = true
      state.loaded = false
    },
    getServicesSuccess: (state, {payload}) => {
      state.services = payload
      state.loading = false
      state.loaded = true
      state.hasErrors = false
    },
    getServicesFailure: (state) => {
      state.loading = false
      state.hasErrors = true
      state.loaded = false
    },
  },
})

export const {getServices, getServicesSuccess, getServicesFailure} = servicesSlice.actions

export const servicesSelector = (state) => state.services

export default servicesSlice.reducer

export function fetchServices() {
  return async (dispatch, getState) => {
    dispatch(getServices())

    const restaurant_choisi = getState().connexion.restaurant_choisi;  
      
    const config = { 
      headers: {'Content-Type' : 'application/json'},
      withCredentials: true
    }

    const response = axios.get(API_URL+'/web/service/'+restaurant_choisi, config)
    .then((res) => {
      dispatch(getServicesSuccess(res.data))
    })
    .catch((error) => {

      if(error.response.status == 401) {
        dispatch(fetchDeconnexion())
      }

      dispatch(getServicesFailure())
    })
      
  }
}