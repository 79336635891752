import {
  createStore,
  getDefaultMiddleware,
  applyMiddleware,
} from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import localForage from "localforage";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";

import servicesReducer from "../features/services/servicesSlice";
import rapportsReducer from "../features/rapports/rapportsSlice";
import serviceReducer from "../features/service/serviceSlice";
import rapportfinancierReducer from "../features/rapportfinancier/rapportfinancierSlice";
import dashboardReducer from "../features/dashboard/dashboardSlice";
import connexionReducer from "../features/connexion/connexionSlice";
import rapportReducer from "../features/rapport/rapportSlice";
import cumulReducer from "../features/cumul/cumulSlice";
import adminReducer from "../features/admin/adminSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  rapport: rapportReducer,
  services: servicesReducer,
  rapports: rapportsReducer,
  service: serviceReducer,
  rapportfinancier: rapportfinancierReducer,
  dashboard: dashboardReducer,
  connexion: connexionReducer,
  cumul: cumulReducer,
  admin: adminReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
