import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import styles from './Cumul.module.css';
import Spinner from 'react-bootstrap/Spinner';
import format from "date-fns/format";
import { registerLocale } from  "react-datepicker";
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from 'date-fns/locale/fr';
import { fetchCumul } from './cumulSlice';
import { setRapportCumul } from  '../rapports/rapportsSlice';
registerLocale('fr', fr)

export const Cumul = () => {
  
  const history = useHistory();
  const dispatch = useDispatch()
  const services = useSelector(state => state.services) 
  var date = new Date();
  var d_min = new Date(date.getFullYear(), date.getMonth(), 1)
  if(date == d_min) { d_min.setDate(d_min.getDate() - 1); }
  const [date_max, set_max_date] = useState(date);
  const [date_min, set_min_date] = useState(d_min);

  const OuvrirRapport = (event) => {
    
  }

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    
    if (date_min < date_max && date_max <= new Date()) {

      var cumul = { 
        "date_min": format(date_min, 'yyyyMMdd'),
        "date_max": format(date_max, 'yyyyMMdd'),
        "date_min_affichage": format(date_min, 'EEEE dd MMMM yyyy', {locale: fr}),
        "date_max_affichage": format(date_max, 'EEEE dd MMMM yyyy', {locale: fr})
      }

      dispatch(setRapportCumul())

      dispatch(fetchCumul(cumul))

    } else {

      alert('La date de début doit être antérieur à la date de fin')
      
    }

  }
  
  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.div_intervalle}>
        <h2>Veuillez choisir une intervalle</h2>
        <label className={styles.label_date} for="date_min">Du : &nbsp;</label>
        <DatePicker selected={date_min} id="date_min" dateFormat="dd/MM/yyyy" locale="fr" popperPlacement="bottom" onChange={(date) => set_min_date(date)} />
        <label className={styles.label_date} for="date_max">&nbsp; au &nbsp;</label>
        <DatePicker selected={date_max} id="date_max" dateFormat="dd/MM/yyyy" locale="fr" popperPlacement="bottom" onChange={(date) => set_max_date(date)} />
        <Button type="submit" variant="outline-danger" className={styles.button} onClick={OuvrirRapport} >Valider</Button>
      </div>
    </form>
  )
}
