import {createSlice} from '@reduxjs/toolkit'

export const initialState = {}

const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    getSelectedService: (state, {payload}) => {
      state.service = payload
    },
  },
})

export const {getSelectedService} = serviceSlice.actions

export const serviceSelector = (state) => state.service

export default serviceSlice.reducer