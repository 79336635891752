import {createSlice} from '@reduxjs/toolkit'
import { fetchDeconnexion } from '../connexion/connexionSlice'
import {API_URL} from '../../Constants'
import axios from 'axios'
import history from '../../history'

export const initialState = {
  cumul: {},
}

const cumulSlice = createSlice({
  name: 'cumul',
  initialState,
  reducers: {
    getCumul: (state, {payload}) => {
      state.cumul = payload
    }
  },
})

export const {getCumul} = cumulSlice.actions

export const cumulSelector = (state) => state.cumul

export default cumulSlice.reducer

export function fetchCumul(cumul) {
  return async (dispatch) => {

    dispatch(getCumul(cumul))

    history.push("/service/rapports")

  }
}