import { createSlice } from '@reduxjs/toolkit'
import history from '../../history'

export const initialState = {
  rapport: {},
}

const rapportSlice = createSlice({
  name: 'rapport',
  initialState,
  reducers: {
    getSelectedRapport: (state, {payload}) => {
      state.rapport = payload
    }
  },
})

export const {getSelectedRapport} = rapportSlice.actions

export const rapportSelector = (state) => state.rapport

export default rapportSlice.reducer

export function fetchRapport(rapport) {
  return async (dispatch) => {

    dispatch(getSelectedRapport(rapport))

    history.push("/rapport/financier")

  }
}